/* @media ( 1201px  <= width <= 1600px) { */
@media (min-width: 1201px) and (max-width: 1600px){
    /* .base-mainMenu
    {
        width: 25%;
    }
    .main-menu
    {
        width:  25%;
    }
    .base-items
    {
        width: 75%;
    } */
    .tails .text-tails
    {
        /* font-size: 21px; */
    }
    .tails .fs-number-tails
    {
        font-size: 64px;
    }
    .tails .title-tail
    {
        font-size: 24px;
    }
}


/* @media ( 993px  <= width <= 1200px) { */
@media (min-width: 993px) and (max-width: 1200px){
    /* .base-mainMenu
    {
        width: 25%;
    }
    .main-menu
    {
        width: 25%;
    }
    .base-items
    {
        width: 75%;
    } */
    .tails .text-tails
    {
        font-size: 17px;
    }
}
/* @media ( 768px  <= width <= 992px) { */
@media (min-width: 768px) and (max-width: 992px){
    /* .base-mainMenu
    {
        width: 30%;
    }
    .main-menu
    {
        width: 30%;
    }
    .base-items
    {
        width: 70%;
    } */
}
/* @media ( 576px  <= width <= 767px) { */
@media (min-width: 576px) and (max-width: 767px){
    /* .base-mainMenu
    {
        width: 40%;
    }
    .main-menu
    {
        width:  40%;
    }
    .base-items
    {
        width: 60%;
    } */
        /* popup */
    .lightContainer
    {
        width: 80%;
        transform: translate(50%,-50%);

    }
}
/* @media ( 0px  <= width <= 575px) { */
@media (min-width: 0px) and (max-width: 575px){
    /* .base-mainMenu
    {
        width: 40%;
    }
    .main-menu
    {
        width:  40%;
    }
    .base-items
    {
        width: 60%;
    } */
        /* popup */
    .lightContainer
    {
        width: 95%;
        transform: translate(50%,-50%);

    }
    .parent-tails
    {
        margin-inline: 0px;
    } 
}

/* @media ( 0px  <= width <= 399px) {
    /* .base-mainMenu
    {
        width: 40%;
    }
    .main-menu
    {
        width:  40%;
    }
    .base-items
    {
        width: 60%;
    } */
        /* popup */
    /* .lightContainer
    {
        width: 95%;
        transform: translate(50%,-50%);

    } */
/* }  */






                /* main menu and base component and tails dashboard   */
/* @media ( 1680px  < width) { */
@media (min-width: 1681px) {
    .base-mainMenu
    {
        width: 16%;
    }
    .main-menu
    {
        width:  16%;
    }
    .base-items
    {
        width: 84%;
    }
    /* .parent-nav-bar
    {
        width: 84%;
    } */
    .tails .text-tails
    {
        font-size: 21px;
    }
}
/* @media ( 1480px  < width <= 1680px) { */
@media (min-width: 1481px) and (max-width: 1680px){
    .base-mainMenu
    {
        width: 18%;
    }
    .main-menu
    {
        width:  18%;
    }
    .base-items
    {
        width: 82%;
    }
    /* .parent-nav-bar
    {
        width: 82%;
    } */
    .tails .text-tails
    {
        font-size: 21px;
    }
    .tails .fs-number-tails
    {
        font-size: 64px;
    }
    .tails .title-tail
    {
        font-size: 24px;
    }
}
/* @media ( 1300px  < width <= 1480px) { */
@media (min-width: 1300px) and (max-width: 1480px){
    .base-mainMenu
    {
        width: 20%;
    }
    .main-menu
    {
        width:  20%;
    }
    .base-items
    {
        width: 80%;
    }
    /* .parent-nav-bar
    {
        width: 80%;
    } */
    .tails .text-tails
    {
        font-size: 21px;
    }
    .tails .text-tails
    {
        font-size: 21px;
    }
    .tails .fs-number-tails
    {
        font-size: 48px;
    }
    .tails .title-tail
    {
        font-size: 21px;
    }
}
/* @media ( 1200px  < width <= 1300px) { */
@media (min-width: 1200px) and (max-width: 1300px){
    .base-mainMenu
    {
        width: 22%;
    }
    .main-menu
    {
        width:  22%;
    }
    .base-items
    {
        width: 78%;
    }
    /* .parent-nav-bar
    {
        width: 78%;
    } */
    .tails .text-tails
    {
        font-size: 21px;
    }
    .tails .fs-number-tails
    {
        font-size: 48px;
    }
    .tails .title-tail
    {
        font-size: 18px;
    }
}

/* @media ( 1200 >= width ) { */
@media (max-width: 1200px) {
    .base-mainMenu
    {
        width: 0px;
    }
    .main-menu
    {
        width: 275px;
        z-index: 2;
        /* right: 0px; */
        display: none;
        /* border-top-left-radius: 40px; */
    }
    .base-items
    {
        width: 100%;
    }
}
/* @media ( 1001px <= width <= 1200px ) { */
@media (min-width: 1001px) and (max-width: 1200px){
    .tails .text-tails
    {
        font-size: 21px;
    }
    .tails .fs-number-tails
    {
        font-size: 54px;
    }
    .tails .title-tail
    {
        font-size: 20px;
    }
}
/* @media ( 801px <= width <= 1000px ) { */
@media (min-width: 801px) and (max-width: 1000px){

    .tails .fs-number-tails
    {
        font-size: 40px;
    }
    .tails .title-tail
    {
        font-size: 18px;
    }
}
/* @media (768px <= width <= 800px ) { */
@media (min-width: 768px) and (max-width: 800px){

    .tails .fs-number-tails
    {
        font-size: 32px;
    }
    .tails .title-tail
    {
        font-size: 16px;
    }
}
/* @media (601px <= width <= 767px ) { */
@media (min-width: 601px) and (max-width: 767px){

    .tails .fs-number-tails
    {
        font-size: 64px;
    }
    .tails .title-tail
    {
        font-size: 26px;
    }
}
/* @media (401px <= width <= 600px ) { */
@media (min-width: 401px) and (max-width: 600px){

    .tails .fs-number-tails
    {
        font-size: 36px;
    }
    .tails .title-tail
    {
        font-size: 20px;
    }
}
/* @media ( width <= 400px ) { */
@media ( width <= 400px ) {

    .tails .fs-number-tails
    {
        font-size: 32px;
    }
    .tails .title-tail
    {
        font-size: 16px;
    }
}





