@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Cairo&display=swap');
@import url("./global.css");
@import url("./style.rtl.css");

/* @import url("./bootstrap.utilities.rtl.css"); */

                                /* GLOBAL  */




li {
    list-style: none;
}
ul {
    padding: 0;
    margin: 0;
}
a {
    text-decoration: none;
}
.rtl {
    direction: rtl;
}
.main-menu a{
    text-decoration: none ;
    color: var(--babyBlue-50);
}
.main-menu a:hover
{
    color: var(--secondryColor);
}
p
{
    margin: 0;
}
.bg-myPrimary
{
    background-color: var(--primaryColor);
}
.bg-darkPrimary
{
    background-color: var(--darkPrimary);
}
.text-myPrimary
{
    width: fit-content;
    color: var(--primaryColor);
}
.text-mySecondry
{
    color: var(--secondryColor);
}
.bg-babyblue-50
{
    background-color: var(--babyBlue-50);
}
.fs-18
{
    font-size: 18px;
}
.fs-16
{
    font-size: 16px;
}
.icon-primary
{
    color: var(--primaryColor);
    font-size: 28px;
}
.big-card
{
    min-height: 100%;
    border-top-left-radius: 40px;
    padding-bottom: 20px !important;
}
.header-card
{
    margin: 0 22px 0 20px;
    padding-top: 25px;
}
.cursor-notAllowed {
    cursor: not-allowed !important;
}
@media (min-width: 576px) and (max-width: 639px) {
    .header-card h1 {
        font-size: 22px ;
    }
}
.btn-request{
    background-color: var(--primaryColor) !important;
    color: var(--baseColor) !important;
    font-family: 'Cairo', sans-serif !important;
    font-size: 16px !important;
    border-radius: 10px !important;
    padding: 10px 20px !important;
}
.btn-small-request{
    background-color: var(--primaryColor) !important;
    color: var(--baseColor) !important;
    font-family: 'Cairo', sans-serif !important;
    font-size: 14px !important;
    border-radius: 10px !important;
    padding: 7px 20px !important;
}
.header-table-search .btn-small-request {
    font-size: 12px !important;
    padding: 3px 20px !important;
}
.btn-myPrimary
{
    background-color: var(--primaryColor) !important;
    color: var(--baseColor) !important;
    font-family: 'Cairo', sans-serif !important;
    border-radius: 10px !important;
    padding: 10px 20px !important;
}
.btn-myPrimaryLight
{
    background-color: var(--mediumGray) !important;
    color: var(--baseColor) !important;
    font-family: 'Cairo', sans-serif !important;
    /* text-align: center !important;
    border : 0 !important; */
    border-radius: 10px !important;
    padding: 10px 20px !important;
}
.btn-myPrimary-login
{
    background-color: var(--primaryColor) !important;
    color: var(--baseColor) !important;
    font-family: 'Cairo', sans-serif !important;
    border-radius: 10px !important;
    padding: 8px 20px !important;
    min-width: 81px !important;
}
.btn-myPrimary-delete
{
    background-color: var(--fourthColor) !important;
    color: var(--baseColor) !important;
    font-family: 'Cairo', sans-serif !important;
    border-radius: 10px !important;
    padding: 8px 20px !important;
}
.btn-myPrimary:hover,.btn-request:hover,.btn-myPrimary-login:hover,.btn-small-request:hover
{   
    background-color: var(--darkPrimary)  !important;
}
.btn-myPrimary-delete:hover
{   
    background-color: var(--fourthTile)  !important;
}
.btn-myPrimaryLight:hover
{   
    background-color: var(--light-gray)  !important;
}
.icon-settings-table
{
    color: var(--mediumGray);
}
.icon-disable
{
    color: #cfcfcfc0;
}
.cursor-pointer
{
    cursor: pointer;
}
.label-input
{
    min-width: fit-content;
}
.positive-color {
    color: var(--thirdColor);
}
.negative-color {
    color: var(--fourthColor);
}
            /* search popup */
.light-search-box
{
    position: absolute;
    top: 30%;
    right: 50%;
    transform: translateX(50%);
}
.close-popup-icon
{
    position: absolute;
    top: 0%;
    right: 0%;
    color: var(--baseColor);
    font-size: 28px;
    transform: translate(-50%,50%);
}
.containerBox-search
{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.50);
    height: 100vh;
    width: 100vw;
    inset: 0;
    z-index: 9;
}
.component {
    overflow-y: auto !important;
    height: calc(100vh - 77px);
}
                        /* N a v B ar   */

.parent-nav-bar
{
    width: 100%;
    z-index: 1;
    height: 77px;
}
[dir="ltr"] .nav-bar
{
    background-color:var(--baseColor);
    border-top-left-radius: 40px ;
    padding: 0 20px;
}
.mainMenu-logo-mobile, .menu-icon 
{
    cursor: pointer;
}
.search input:placeholder-shown, .search input, .search input:focus-visible
{
    padding-left : 50px;
    width: 250px;
    font-size: 16px;
}
.search input:focus-visible
{
    border: none;
    outline: -webkit-focus-ring-color auto 0px;
}
.search {
    position: relative;
    padding-left: 12px;
}

.search-bar
{
    border : none;
    background-color: var(--babyBlue-50);
    /* padding-block: 11px !important; */
    padding: 11px 0 !important;
    border-radius: 8px;
    
}
.icon-input
{
    width: fit-content;
    border-radius: 8px;
    background-color: var(--babyBlue-100);
    color : var(--primaryColor);
    padding: 10.5px 15px;
    position: absolute;
    top: 0;
    font-size: 16px;
}
.search-popup .muirtl-igs3ac, .search .muirtl-igs3ac {
    border-width: 0 !important;
}
.search-popup .muirtl-1d3z3hw-MuiOutlinedInput-notchedOutline, .search .muirtl-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-width: 0 !important;
}
.muirtl-gus103-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 3.5px !important;
}
.muirtl-8xeioa .MuiOutlinedInput-root {
    padding: 4px !important;
}
/* table search */

.search-table
{
    border : none;
    background-color: var(--baseColor);
    /* padding-block: 10px; */
    padding: 10px 0;
    border-radius: 15px;
}
.icon-input-table
{
    width: fit-content;
    border-radius: 15px;
    background-color: var(--babyBlue-100);
    color : var(--primaryColor);
    padding: 8.5px 14px;
    position: absolute;
    top: 0;
    font-size: 20px;
}
                        /* M A I N  M E N U */
.main-menu
{
    background-color: var(--darkPrimary);
    color: var(--babyBlue-50);
    position: fixed;
    width: 15%;
    height: 100vh;
    z-index: 5 !important;   
}
@media (max-width: 1200px) {
    [dir=ltr] .main-menu
    {
        left: 0px;
        border-top-right-radius: 40px;
    }
}
.title-list
{
    font-size: 16px;
    padding-top: 8px;
    margin-top: 4px;
    overflow: auto;
    height: calc(100vh - (111.16px + 16px + 30px)) !important;
}
.logo-main-menu
{
    color: var(--secondryColor);
    font-size: 36px;
    text-align: center;
    padding: 20px 20px 20px 20px;
}
.main-menu .title-main-menu
{
    margin-bottom: 15px;
    padding-left: 25px;
    padding-right    : 15px;
    cursor: pointer;
    position: relative;
}
.title-main-menu
{
    display: block;
}
.title-main-menu:hover a, .title-main-menu:hover, .sub-title-main-menu:hover a
{
    color: var(--secondryColor);
}
[dir="ltr"] .title-main-menu:hover::before,[dir="ltr"] .sub-title-main-menu:hover::before
{
    content: '';
    border-right: solid;
    height: 30px;
    border-color: var(--secondryColor);
    border-width: 4px;

    border-bottom-right-radius: 7.5px;
    border-top-right-radius: 7.5px;

    position: absolute;
    left: 0;
    bottom: 0;
    color: var(--secondryColor);
    
}
.active-title-main-menu
{
    color: var(--secondryColor) !important;
}
[dir="ltr"] .active-title-main-menu::before
{
    content: '';
    border-right: solid;
    height: 30px;
    border-color: var(--secondryColor);
    border-width: 4px;

    border-bottom-right-radius: 7.5px;
    border-top-right-radius: 7.5px;

    position: absolute;
    left: 0;
    bottom: 0;
    color: var(--secondryColor);
}
.sub-title-main-menu
{
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 50px;
    padding-right    : 30px;
    position: relative;
    cursor: pointer;
}
[dir=ltr] .icon-main-menu
{
    margin-right: 10px ;
    font-size: 18px;
}
.icon-sub-main-menu
{
    margin-right: 10px ;
    font-size: 16px;
}
.layer-mobile
{
    position: fixed;
    inset: 0;
    background-color: #0000002d;
    z-index: 2;
}
.logout-icon:hover
{
    color: var(--fourthColor);
}
.footer-logout
{
    padding-right: 25px;
    padding-left: 15px;
}
                            /* Base Component */
.base {
    display: flex;
    justify-content: left;
}
.base-mainMenu
{
    width:15%;
}
.base-items
{
    width: 85%;
}
                    /* D A S H B O A R D  */
.parent-tails a, .cards-dashboard a 
{
    text-decoration: none ;
    color: #212529;
}                   
.tails-shape-1, .tails-shape-2, .tails-shape-3, .tails-shape-4
{
    border-radius: 20px;
    padding: 20px;
}
.tails-shape-1
{
    background-color: var(--primaryTile);
}
.tails-shape-2
{
    background-color: var(--secondaryTile);
}
.tails-shape-3
{
    background-color: var(--fifthTile);
}
.tails-shape-4
{
    background-color: var(--thirdTile);
}
.tails .fs-number-tails
{
    font-size: 84px;
}
.tails .title-tail
{
    font-size: 28px;
}
.tails .currency-tail
{
    font-size: 16px;
}
.tails-dashboard
{
    margin: 15px;
   color: var(--darkGray);
}
.tails {
    position: relative;
}
.tails .text-tails
{
    /* position: absolute; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(50%,-50%); */
    color: var(--baseColor);
    font-size: 26px;
    font-weight: bold;
}
.fs-number-tails
{
    font-size: 2.6em;
}
                /* Date Range Picker => react dates  */
.DateInput_input, .DateRangePickerInput, .DateInput, .DateInput_input__small
{
    background-color: transparent !important;
}
.DateInput
{
    border: 0;
}
.DateRangePickerInput__withBorder
{
    border: 0;
    border-bottom: 1px  rgba(0, 0, 0, 0.42) solid !important; 
    width: fit-content !important;
}
.DateRangePickerInput_arrow_svg
{
    display: none;
}
.DateRangePickerInput_arrow
{
    background-color: #727272;
    width: 10px;
    height: 2px;
}
.DateRangePickerInput_clearDates_default:hover
{
    background-color: transparent;
}
.CalendarDay__default:hover
{
    background: var(--babyBlue-100);
    border: 1px solid var(--babyBlue-100);
}
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover
{
    background-color: var(--primaryColor);
}
.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover
{
    background: var(--primaryTile);
    border: 1px double var(--primaryTile);
}
.CalendarDay__hovered_span, .CalendarDay__hovered_span:hover
{
    border-color: var(--primaryTile) ;
    background-color: #0d5bf880; ;
    color: var(--babyBlue-100);
}
.CalendarDay__selected_span
{
    border-color: var(--primaryTile) ;
    background-color: #0d5bf880;
}
.DateInput_input__focused
{
    border-bottom : 2px solid var(--primaryColor) ;
}
                /* End DateRange Picker */

.card-dashboard
{
    padding: 20px;
    background-color: var(--baseColor);
    border-radius: 20px;
}
@media screen and (max-width: 419px) {
    .card-dashboard {
        /* padding-block: 10px; */
        padding: 10px 0;
        /* padding-inline: 6px; */
        padding: 0px 6px;
    }
    .container  {
        padding: 0px;
    }
    .info-card {
        /* padding-inline: 20px !important; */
        padding: 0 20px !important;
    }
    .polets-primary {
        /* padding-inline: 20px !important; */
        padding: 0 20px !important;
    }
    .polets-secondry{
        /* padding-inline: 20px !important; */
        padding: 0 20px !important;
    }
}
.polets-primary, .polets-secondry
{
    position: relative;
    /* padding-inline: 25px; */
    padding: 0 25px;
    
}
[dir="ltr"] .polets-primary::before
{
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--primaryColor);
}
[dir="ltr"] .polets-secondry::before
{
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--secondryColor);
}

.info-card
{
    /* padding-inline: 25px; */
    padding: 0 25px;
    /* margin-block: 6px 15px ; */
    margin: 6px 0 15px 0 ;
}
.line-card
{
    margin-top: 8px ;
    color: #9292929c;
}
.DateRangePickerInput {
    width: auto !important;
  }

.DateRangePicker_picker {
    z-index: 100 !important;
}
.count-chart
{
    position: absolute ;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    font-size: 40px;
    color: var(--primaryColor);
    font-weight: 600;
}
                    /* C A L E N D E R  */
/* .big-card-calender
{
    border-top-left-radius: 40px;
    height: calc(100vh - 77px);
    padding-bottom: 20px ;
} */
.calender-table{
    position: relative !important;
    z-index: 1 !important;
}
.calender-table a {
    text-decoration: none ;
}
.calender-table h2 {
    color: var(--primaryColor) !important;
}
.calender-table button 
{
    /* background-color: var(--darkPrimary) !important; */
    /* color: var(--baseColor) !important; */
}

.month-view
{
    height:76vh;
}
        /* availableTimeCalender */
z.rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label
{
    font-size: 14   px;
}
                    /* U N I T E S  */
.ag-theme-alpine {

  --ag-font-family: 'Cairo', sans-serif;
  --ag-font-size: 12px;
  /* direction: rtl; */

}
.ag-root-wrapper {
    /* border-width: 3px; */
    background-color: var(--baseColor);
    border-color: var(--babyBlue-100);
    border-width: 0px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
                /* A D D   U N I T E S  */
.info-cards-Unites
{
    background-color: var(--baseColor);
    padding: 12px;
    border-radius: 20px;
    margin-top: 16px;
}
.textfield-input
{
    width: 100%;
}
.label-text
{
    vertical-align: text-bottom ;
    align-items: bottom;
}
.policy-cards-Unites
{
    /* padding-inline: 12px; */
    /* margin-top: 12px; */
}
.pin-marker-map {
    position: absolute;
    bottom: 50%;
    transform: translateX(50%);
    left: 50%;
    padding: 0;
}
.arrow-card-icon {
    color: var(--mediumGray);
    font-size: 14px;
}
        /*-----UPLOAD IMAGE----  */
.icon-upload-image
{
    color: var(--secondryColor);
    font-size: 48px;
    margin-bottom: 20px;
    cursor: pointer;
}
.btn-upload-files
{
    background-color: transparent !important;
}
.btn-upload-files:hover
{
    background-color: transparent !important;
    color: var(--darkPrimary) !important;
}
.icon-upload-image:hover
{
    color: var(--darkPrimary) ;
}
.image-preview
{
    position: relative;
}
.icon-delete-image
{
    border-radius: 15px;
    border-width: 0;
    border-color: transparent;
    background-color: var(--fourthColor);
    /* width: 20px;
    height:20px; */
    /* padding-inline: 6px; */
    padding: 0 6px;
    margin: auto;
    color: #fff;
    position: absolute;
    right: 0;
    transform: translate(0%, -50%);
}
.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    z-index: -1;
}
.label-upload
{
    font-size: 12px;
}
.fileContainer
{
    box-shadow : none;
    padding : 0;
}
.fileContainer .uploadPictureContainer
{
    width: 16%;
    margin: 1%;
    padding: 20px;
    background: var(--babyBlue-50);
    border: 1px solid #d0dbe4;
    position: relative;
}
/* @media  ( 993px  <= width <= 1201px) { */
@media (min-width: 993px) and (max-width: 1201px) {
    .fileContainer .uploadPictureContainer
    {
        width: 18%;
        padding: 16px;

    }
}
/* @media ( 768px  <= width <= 992px) { */
@media (min-width: 768px) and (max-width: 992px) {
    .fileContainer .uploadPictureContainer
    {
        width: 22%;
        padding: 14px;
    }
}
/* @media ( 400px  <= width <= 767px) { */
@media (min-width: 400px) and (max-width: 767px) {
    .fileContainer .uploadPictureContainer
    {
        width: 30%;
        padding: 10px;
    }
}
.fileContainer .deleteImage
{
    background-color: var(--fourthColor);
    font-size: 18px;
    line-height: 25px;
    width: 26px;
    height: 26px;
}
.desc-input
{
    position: relative;
}
.remaining-char
{
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: var(--primaryColor);
}
                /* R E Q U E S T S */
.icon-false
{
    margin-left: 20px ;
}
                /* A D D   R E Q U E S T S */
.containerBox
{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.25);
    height: 100vh;
    width: 100vw;
    inset: 0;
    z-index: 10;
}
.lightContainer
{
    padding: 15px 30px 15px 30px;
    position: absolute;
    top: 50%;
    background-color: var(--baseColor);
    border-radius: 20px;
    width: 620px;
    min-width: 400px;
    max-height: 90vh;
    overflow-y: auto ;
}
[dir=ltr] .lightContainer
{
    left: 50%;
    transform: translate(-35%,-50%);
}
/* @media (width <= 1200px) { */
@media (max-width: 1200px) {
    [dir=ltr] .lightContainer
    {
        transform: translate(-50%,-50%);
    }
}
.request-popup
{
    height: 500px;
    overflow-y: auto;
}
.line-report
{
    margin-top: 8px ;
    color: #5c5b5bf5;
}
.input-calender-addRequest .DateRangePickerInput__withBorder {
    border: 0;
    border-bottom: 2px rgb(0 0 0 / 0%) solid !important;
}
[dir=ltr] .input-calender-addRequest .DateInput_input {
    
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    font-size: 16px !important;
    padding-left: 0px !important;
}
.input-calender-addRequest .DateInput_input__focused {
    border-bottom: 2px solid var(--primaryColor);
}
.input-calender-addRequest .DateRangePickerInput_arrow
{
    /* display: none; */
    background-color: transparent;
    /* padding-inline: 20px ; */
    padding: 0 20px;
}
.input-calender-addRequest .DateInput{
    width: 46%;
}
/* @media (width <= 740px) { */
@media (max-width: 740px) {
    .input-calender-addRequest .DateInput{
        width: 45%;
    }
}
/* @media (width <= 615px) { */
@media (max-width: 615px) {
    .input-calender-addRequest .DateInput{
        width: 44%;
    }
}
/* @media (width <= 448px) { */
@media (max-width: 448px) {
    .input-calender-addRequest .DateInput{
        width: 43%;
    }
}
.input-calender-addRequest .DateRangePicker
{
    width: 100%;
}
.input-calender-addRequest .DateRangePickerInput__withBorder {
    border: 0;
    border-bottom: 1px rgb(0 0 0 / 0%) solid !important;
    width: 100% !important;
    /* width: 100% !important; */
}
.report-request
{
    background-color: var(--babyBlue-75);
    border-radius: 20px ;
    padding: 20px ;
}
.disable-date-picker, .disable-date-picker input
{
    opacity: 0.8;
    cursor: not-allowed !important;
}
.text-notAvailable
{
    color: var(--secondryColor);
    margin-top: -10px;
    font-size: 14px;
}
                    /* COSTS */
                /* A D D   C O S T */
                /* MUI DATE PICKER */
.ag-theme-alpine-Addcost {

    --ag-font-family: 'Cairo', sans-serif;
    --ag-font-size: 12px;
    }
.ag-root-wrapper-Addcost {
    background-color: var(--baseColor);
    border-color: var(--babyBlue-100);
    border-width: 0px;
    border-radius: 10px !important;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}
            /* TASKS */
            /* ADD TASKS */

/* Single Calender  */
.SingleDatePicker
{
    display: inline;
}

.SingleDatePickerInput
{
    background-color: transparent;
    display: flex;
}
.SingleDatePickerInput_calendarIcon
{
    margin-left: auto;
}
.SingleDatePicker_picker
{
    z-index: 3;
}
.SingleDatePickerInput__withBorder
{
    border : 0 ;
    border-bottom: var(--mediumGray) 1px solid;
}
.SingleDatePickerInput_clearDate__default:hover
{
    background-color: transparent;
}
.SingleDatePickerInput_clearDate
{
    transform: translateY(-55%);
}
/* USER */
            /* ADD USER */
.text-helper-invalid{
    font-size: 14px;
    color: #dc3545;
    padding-left: 10px;
}
.text-helper{
    font-size: 14px;
    color: var(--mediumGray);
    padding-left: 10px;
}

            /* S E T T I N G S   */
.info-cards-settings
{
    background-color: var(--baseColor);
    padding: 20px 20px 20px 20px;
    border-radius: 20px;
    margin-top: 16px;
}
.profile-image
{
    position: relative;
    border-radius: 20px;
    height: 235px;
    width: 200px;
    background-color: var(--babyBlue-75);
}
.icon-upload-image-profile
{
    position: absolute;
    top: 70%;
    transform: translate(50%, -50%);
    font-size: 48px;
    color: var(--babyBlue-100);
}
[dir="ltr"] .icon-xmark-image-profile {
    position: absolute;
    top: 15px;
    right: 15px;
    color: var(--darkGray);
}
.inputs-info-settings
{
    width: 50%;
}
/* @media ( 575px  >= width ) { */
@media (max-width: 575px){
    .inputs-info-settings
    {
        width: 100%;
    }
}
/* .profile-img:hover .profile-image::after
{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 20px;
    transform: translate(50%, -50%);
    background-color: #33333346;
    width: 100%;
    height: 100%;
    animation: name duration timing-function delay iteration-count direction fill-mode;
} */
/* .profile-img:hover .icon-upload-image-profile
{
    color: var(--mediumGray)
} */
.profile-image img 
{
    border-radius: 20px;
}
/* .settings-big-card
{
    height: 90.7vh;
}  */

        /* L O G I N  */
.parent-login
{
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 14;
}
.parent-card-login
{
    width: fit-content;
    border-radius: 40px ;
    margin: auto;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 10px 3px;

}
.info-card-login
{
    background-color: var(--baseColor);
    border-bottom-left-radius: 40px;
    border-top-left-radius: 40px;
    padding: 40px;
    min-width: 420px;
}
.title-login
{
    text-align: start;
}
[dir=ltr] .logo-login
{
    background-color: var(--primaryColor);
    padding: 40px 20px;
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
}
                    /* L O G I N    M O B I L E  */
[dir=ltr] .login-mobile-1
{
    /* height: 50vh; */
    /* background-color: var(--baseColor); */
    background-color: var(--primaryColor);
    border-bottom-left-radius: 80px;
    padding: 50px;
}
.logo-mobile
{
    /* width: 165px; */
    width: 170px;
    /* width: 165px; */
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.login-mobile-2
{
    background-color: var(--primaryColor);
}
[dir=ltr] .info-card-login-mobile
{
    background-color: var(--baseColor);
    border-top-right-radius: 80px;
    padding: 40px 60px;
    border: 0;
}
/* .add-popup
{
    display: none;
} */
.add-confirm, .add-popup, .limited-resources{
    display: none;
}
.border-color {
    border-bottom: 1px solid red !important;
}
        /* E N D  L O G O  M O B  I L E */
        /* START Report Unit Cost */
.report-cost-card {
    background-color: var(--babyBlue-100);
    /* color: var(--baseColor); */
    border-radius: 10px;
}
.report-button {
    width: fit-content;
}
.reports-button-icon
{
    width: 15%;
}
            /* END Report Unit Cost */

/* MUI MESSGAE SUCCESS AND ERROR */

.muirtl-cpgvjg-MuiSnackbar-root
{
    /* top: -83% !important; */
}

/* MUI ICON SUCCESS MESSGAE  */

.successMessage .muirtl-1vooibu-MuiSvgIcon-root
{
    transform: rotate(1deg) !important;
}

/* Scroll bar*/
.scrollbar-style::-webkit-scrollbar-track
{
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2); */
	border-radius: 10px;
	background-color: transparent;
}

.scrollbar-style::-webkit-scrollbar
{
	width: 8px;
	background-color: transparent;
}

.scrollbar-style::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.2);
	background-color: var(--babyBlue-100);
}

.containerBox .scrollbar-popup-style::-webkit-scrollbar
{
    background-color: transparent;
    width: 0;
	border-radius: 10px;
}

/* Am Charts */

/* #chartdiv {
    font-family: 'Cairo', sans-serif !important;
} */

.amchart-Container {
    font-family: 'Cairo', sans-serif !important;
}
.amchart-title-main {
    font-family: 'Cairo', sans-serif !important;
}
.amchart-Label {
    font-family: 'Cairo', sans-serif !important;
}
            /* ============ N O T   F O U N D ==================  */

.page_404 {
    padding: 40px 0;
    /* background: var(--primaryColor); */
}
.page_404 img {
    width: 100%;
}
.four_zero_four_bg {
    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center center;
}
.four_zero_four_bg h1 {
    font-size: 80px;
}
.four_zero_four_bg h3 {
    font-size: 80px;
}
.link_404 {
    color: var(--baseColor) ;
    border-radius: 10px;
    border-width: 0;
    padding: 10px 20px;
    background: var(--secondryColor);
    margin: 20px 0;
    display: inline-block;
}
.contant_box_404 {
    margin-top: -50px;
}

.form-control-border-error
{
    border: #dc3545 1px solid !important;    
}

.muirtl-trpmj5-MuiAutocomplete-root .MuiOutlinedInput-root{
    padding: 0px 9px 9px 20px !important;
}

            /* Start Toggle Button */
.btn-container {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.btn-color-mode-switch {
  display: inline-block;
  margin: 0px;
  position: relative;
}

[dir=ltr] .btn-color-mode-switch > label.btn-color-mode-switch-inner {
    margin: 0px;
    width: 300px;
    height: 50px;
    background-color: var(--babyBlue-100);
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    /* box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset; */
    display: block;
}

[dir=ltr] .btn-color-mode-switch > label.btn-color-mode-switch-inner:before {
  content: attr(data-on);
  position: absolute;
  font-size: 20px;
  font-weight: 600;
  top: 7px;
  left: 20px;
  color: #222;
  cursor: pointer;
}

[dir=ltr] .btn-color-mode-switch > label.btn-color-mode-switch-inner:after {
    content: attr(data-off);
    width: 150px;
    height: 46px;
    background: var(--primaryColor);
    border-radius: 20px;
    position: absolute;
    font-size: 20px;
    display: flex;
    justify-content: center;
    right: 2px;
    top: 2px;
    text-align: center;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 6px -2px #111;
    padding: 5px 0px;
    color: var(--baseColor);
    font-weight: 600;
    cursor: pointer;
}

[dir=ltr] .btn-color-mode-switch input[type="checkbox"] {
  cursor: pointer;
  width: 50px;
  height: 25px;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  margin: 0px;
}

[dir=ltr] .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner {
  background-color: var(--babyBlue-100);
}

[dir=ltr] .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:after {
  content: attr(data-on);
  right: 148px;
}

[dir=ltr] .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:before {
  content: attr(data-off);
  left: auto;
  right: 20px;
}

            /* End Toggle Button */
            
            /* Start CloseSnackBar */
.closeSnackbar
{
    background-color: transparent;
    color: #DDD;
    font-size: 16px;
    border : 0;
    padding: 5px;
    border-radius: 5px;
}
[dir=ltr] .go703367398 {
    margin-right: 0px !important;
    padding-right: 0px !important;
    margin-left: auto !important;
}
            /* End CloseSnackBar */

            /* Start pricing and availableTime calender */
.settings-calender-card
{
    border: 2px solid var(--babyBlue-100);
    border-radius: 20px;
}
.settings-calender
{
    margin-top: 70px;
}
#availableTimeCalender .DateRangePickerInput_arrow
{
    background-color: transparent;
    width: 0px;
    height: 0px;
}
#availableTimeCalender .DateInput
{
    display: block;
}
            /* End pricing and availableTime calender */

.MuiTooltip-tooltipPlacementTop
{
    font-size: 20px !important;
    text-align: center;
}