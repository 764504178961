:root
{
    --baseColor : #fff;
    --babyBlue-50 : #EFF3FB;
    --babyBlue-75 : #EBEFFE;
    --babyBlue-100 : #D3DFF7;


    /* --darkPrimary : #102652; */
    /* --primaryColor : #0F5EF7; */

    --darkPrimary : #05389b;
    --primaryColor : #074acc;
    --secondryColor : #FF9E01;
    --thirdColor : #0FAC83;
    --fourthColor : #CC3F0C;
    --fifthColor : #18BECB;

    --light-gray: #999999;
    --mediumGray : #808080;
    --darkGray : #333333;

    --primaryTile : #0d5bf8bf;
    --secondaryTile : rgba(255, 157, 0, 0.75);
    --thirdTile : rgba(15, 174, 131, 0.75);
    --fourthTile : rgba(204, 64, 13, 0.75);
    --fifthTile : rgba(24, 191, 203, 0.75);    
}
    *{
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }
body {
    height: 100%;
}
